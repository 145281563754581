@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    @apply bg-gray-100;
}

header {
    @apply bg-blue-500 p-4 text-white flex items-center;

    img {
        @apply mr-3;
    }

    div h1 {
        @apply text-2xl font-bold;
    }

    div h2 {
        @apply text-lg;
    }
}

main {
    @apply container mx-auto my-4;
}

#description {
    @apply w-full p-4 border rounded-md mb-4 bg-gray-200;

    p {
        @apply text-lg text-gray-700;

        a {
            @apply text-blue-500 underline hover:text-blue-700;
        }
    }
}

#drawing {
    @apply flex flex-col mt-4;
}

footer {
    @apply bg-blue-500 p-4 text-white;
}

#editor {
    @apply flex flex-col p-4;
}

#configReveal {
    @apply mt-4 bg-gray-400 text-white px-0 py-0 rounded-md hover:bg-gray-500;
}

#configOptions {
    @apply flex flex-col p-4;
    display: none;

    div {
        @apply flex flex-row px-4 py-2;

        label {
            @apply border  rounded-md w-2/12 px-4 py-2;
        }

        input {
            @apply border border-gray-800 rounded-md px-4 py-2 ml-5;
        }

        select {
            @apply border border-gray-800 rounded-md px-4 py-2;
        }
    }
}

#inputTextAreaId {
    @apply block text-sm font-medium text-gray-700;
}

#inputTextArea {
    @apply font-mono w-full h-full p-4 border rounded-md mb-4;
}

#generate {
    @apply mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600;
}

#toAbsolute {
    @apply mt-4 bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600;
}

#drawingError {
    @apply mt-4 px-4 py-2 rounded-md;
}

#outputArea {
    @apply p-4 border rounded-md bg-white ml-4 mr-4 max-w-full max-h-full overflow-auto;
}

@media screen and (max-width: 767px) {
    header {
        flex-direction: column;
    }
}